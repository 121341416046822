import "./App.css";
import contact from "./images/contact.webp";
import logo from "./images/logo.webp";
import oil from "./images/oil.webp";

function App() {
  return (
    <div className="App">
      <div className="logo_container">
        <img className="logo_icon" src={logo} alt="supreme oil service logo" />
        <span>Supreme Oil Service</span>
      </div>
      <h1 className="tim">TIME IS MONEY.</h1>
      <h2 className="save_both">SAVE BOTH.</h2>
      <div className="ww">
        Wherever you are. Whenever you want.*
        <p className="wcty">We come to you for full-service oil changes.</p>
      </div>
      <div className="bundle_container">
        <img className="img" src={oil} alt="Pouring oil into car." />
        <div>
          <h3>Supreme Oil Change Bundle:</h3>
          <ul>
            <li>
              <div src={""} alt="check mark" className="check" />
              <span>Full Synthetic Oil Change</span>
            </li>
            <li>
              <div src={""} alt="check mark" className="check" />
              <span>New Oil Filter</span>
            </li>
            <li>
              <div src={""} alt="check mark" className="check" />
              <span>Full Washer Fluid Top-Off</span>
            </li>
            <li>
              <div src={""} alt="check mark" className="check" />
              <span>Multi-Point Inspection</span>
            </li>
            <li>
              <div src={""} alt="check mark" className="check" />
              <span className="bold">We Come to You</span>
            </li>
          </ul>
        </div>
      </div>
      <p className="tim">All for Just $97.99!</p>
      <div className="cta">
        <p className="cot">Call or text to schedule your next oil change.</p>
        <a href="tel:801-998-2312">
          <img className="cot_icon" src={contact} alt="call or text" />
          801-998-2312
        </a>
      </div>
      <div className="footer">
        *Proudly serving Utah County Monday - Friday 9 AM to 5 PM
      </div>
    </div>
  );
}

export default App;
